// ArtCarousel.js
import React, { Component } from 'react';
import '../styles/ArtCarousel.css'; 

export default class ArtCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      showPopup: false,
    };
  }

  goToPrevious = () => {
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex === 0 ? this.props.images.length - 1 : prevState.currentIndex - 1,
    }));
  };

  goToNext = () => {
    this.setState(prevState => ({
      currentIndex: (prevState.currentIndex + 1) % this.props.images.length,
    }));
  };

  resetToFirstImage = () => {
    this.setState({ currentIndex: 0 });
  };


  render() {
    const { images } = this.props;
    const { currentIndex} = this.state;

    return (
      <div className="carousel-container">
        <div className="carousel" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {images.map((image, index) => (
            <div
              key={index}
              className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
            >
              <img src={image.src} alt={image.caption} />
            </div>
          ))}
        </div>
        <button className="carousel-control left" onClick={this.goToPrevious}>&lt;</button>
        <button className="carousel-control right" onClick={this.goToNext}>&gt;</button>
      </div>
    );
  }
}