import React, { Component } from 'react'
import classNames from 'classnames';
import ArtCarousel from './ArtCarousel';
import "../styles/artMenu.css"
//digital art
import listen from "../assets/Digital/Listen.PNG"
import attack from "../assets/Digital/Attacked.PNG"
import pink from "../assets/Digital/Cone.PNG"
import drown from "../assets/Digital/Peace.png"
import lost from "../assets/Digital/Lost.PNG"


export default class ArtMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeMenuItem: 1,
    };
  }
  handleMenuItemClick = (menuItem) => {
    this.setState({
      activeMenuItem: menuItem,
    }, ()=>{
      if (this.carouselRef) {
        this.carouselRef.resetToFirstImage();
      }
    }
  );
  }

  render() {
    const { activeMenuItem } = this.state;
    const menuItems = ["DIGITAL", "TRADITIONAL"];

    // Define your images and captions for digital and traditional art
    const digitalImages = [
      { src: listen, caption: "Listening to music" },
      { src: attack, caption: "Attacked" },
      { src: pink, caption: "Fragility of heat" },
      { src: drown, caption: "Peace and Tranquility" },
      { src: lost, caption: "huh" },
    ];

    const traditionalImages = [
      { src: listen, caption: "Listening to music" },
      // ... more traditional art images
    ];


    return (
      <div className='art-menu'>
        {menuItems.map((item, index) => (
          <div
            key={index}
            className={classNames("art-item", {
              activeArt: activeMenuItem === index + 1,
            })}
            onClick={() => this.handleMenuItemClick(index + 1)}>
            <h1 className='art-title'>{item}</h1>
          </div>
        ))}
        <div className='art-sub-container'>
           {activeMenuItem === 1 ? (
            <ArtCarousel ref={(ref) => { this.carouselRef = ref; }} images={digitalImages} />
          ) : (
            <ArtCarousel ref={(ref) => { this.carouselRef = ref; }} images={traditionalImages} />
          )}
        </div>
      </div>
    );
  }
}
