import React, { Component } from 'react'
import classNames from 'classnames'
import "../styles/aboutMenu.css"

const AboutSubheadings =({title, content, active, onClick, menuItem})=> {
  const subContainerClass=`sub-container-${menuItem}`
  return(
    <div className={classNames(subContainerClass,{"active-subheading":active})}>
      <h3 onClick={onClick}>{title}</h3>
      <p className='p-container'>{content}</p>
    </div>
  )
}

export default AboutSubheadings;