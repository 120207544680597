import projectOne from "../assets/project-1.png";
import projectTwo from "../assets/project-2.png";
import projectThree from "../assets/project-3.png";

const projects ={
    1:{
        title: "Random Project1",
        image: projectOne,
        description: (
            <>
                <p>Random description</p>
            </>
        ),
        link: "link to project",
        demo: "link to demo",
    },
    2:{
        title: "Random Project2",
        image: projectTwo,
        description: (
            <>
                <p>Random description</p>
            </>
        ),
        link: "link to project",
        demo: "link to demo",
    },
    3:{
        title: "Random Project3",
        image: projectThree,
        description: (
            <>
                <p>Random description</p>
            </>
        ),
        link: "link to project",
        demo: "link to demo",
    }
};

export default projects;