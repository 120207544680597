import React from "react";
import "../styles/playerStats.css";

var month_diff = Date.now() - new Date("06/21/2007").getTime();
var age_diff = new Date(month_diff);
const age = Math.abs(age_diff.getUTCFullYear() - 1970);

const PlayerStats = () => {
    return (
        <div id="playerStats">
            <h1>SUMMER GUO<br /> 
                AGE {age}</h1>
            
            <h2>Artist, Poet, Cinephile, Foodie, Inventor</h2>
        </div>
    )
}

export default PlayerStats;