const subheadingsData = {
    1: [
        {
            title: "About me",
            content: (<>
                <p>
                    Hello there! I am Summer Guo, an aspiring Computer Science major. 
                </p>
            </>)
        },
    ],
    2: [
        {
            title: "Website Introduction",
            content: (<>
                <p>
                    This website is programmed using HTML, React.js/Javascript, and CSS.
                    You may notice that the style of my website resembles some video games, and this is intentional
                    because as an appreciator for well-made games, I wanted to translate one of my interests into
                    something I made.
                    The contents of this website will include my past artworks, projects, and poems or proses I wrote.
                </p>
            </>)
        },
    ],
    3: [
        {
            title: "Placehold",
            content: (<>
                <p>
                   Placehold
                </p>
            </>)
        },
    ],
};

export default subheadingsData;