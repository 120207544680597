import React, { Component } from 'react'
import poems from './BlogData';
import '../styles/blogMenu.css';

export default class Blog extends Component {
  constructor(props){
    super(props);
    this.state={
      filter:'all',
      selectedPostId: null
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handlePostClick=this.handlePostClick.bind(this);
  }

  handleFilterChange(event) {
    this.setState({ filter: event.target.value, selectedPostId: null });
  }

  handlePostClick(postId) {
    this.setState(prevState => ({
      selectedPostId: prevState.selectedPostId === postId ? null : postId
    }));
  }

  render() {
    const { filter, selectedPostId } = this.state;
    const sortedPoems = poems
      .filter(poem => filter === 'all' || poem.type === filter)
      .sort((a, b) => new Date(b.date) - new Date(a.date));
    return (
<div className="blog-page">
        <div className="filter-container">
        <h2 className={`filter-option ${filter === 'all' ? 'active' : ''}`} onClick={() => this.setState({ filter: 'all' })}>
          All
        </h2>
        <h2 className={`filter-option ${filter === 'poem' ? 'active' : ''}`} onClick={() => this.setState({ filter: 'poem' })}>
          Poems
        </h2>
        <h2 className={`filter-option ${filter === 'prose' ? 'active' : ''}`} onClick={() => this.setState({ filter: 'prose' })}>
          Proses
        </h2>
        </div>
        <div className="blog-container">
          <div className='contents'>
          {sortedPoems.map(poem => (
            <div key={poem.id} className="blog-post" onClick={() => this.handlePostClick(poem.id)}>
              <div className="blog-header">
                <p className='blog-title'>{poem.title}</p>
                <p className="date">{poem.date}</p>
              </div>
              {selectedPostId === poem.id && (
                <pre className="blog-content">{poem.content}</pre>
              )}
            </div>
          ))}
          </div>
        </div>
      </div>
    )
  }
}
