const poems = [
  {
    id: 1,
    title: "Downpour",
    content: `
Hope is the rain
Falling in bouts,
into the drain,
when you have no doubts.
Slithering to the city's core,
fresh and vigorous
We sing for more,
feign ignorance.

Yet when engulfed, we have,
it floods the streets-
screeching and ravaging, through
our city's grease, but
Trivial and ignored,
what good could it be?

For where is the rain,
when the earth cries?
When its skin is cracked, crusty
and dry
A downpour then, a miracle.
Do us good, but
that's atypical

Misleadingly sly, sky prowls
clouds crowd light and
thunder growls
Yet water doesn't fall-
broken promise, 'white lie'.

Some days we forget
the presence of rain when we talk, fret
when we're distracted, it trickles
back to the sea, till summoned up
and down it will drizzle.`,
    date: "2022-01-01",
    type:"poem",
  },
  {
    id: 2,
    title: "Dream",
    content: 
  `
Some days life looks like a dream,
Particular radiance lingering
on the side of a dirt brown building, festering
A cruel yearning. Rays flood from the seam
In the harsh blue sky.
Mountains clawing at the high
heavens, alight with sprightly green

The streets littered with swaying trees, 
people saunter about, gazes 
pointed downwards.
Perhaps in a few centuries time, 
Perhaps sooner than expected,
someone will look
Back and remark
Life looked like heaven
back then.
  
  `,
    date: "2021-08-15"
    ,
    type:"poem",
  },
  {
    id: 3,
    title: "What’s a life worth?",
    content: 
  `
Fleeting line briefly scrolled 
Over, melting into
crashing currents of text, static. Their souls
Escape as whispers through
stiff lips, gaze clammy and cold.

One soul slithers and grasps onto stone, when 
a withering hand scoops.
With every etch, it presses,
Moulds the soul, 
crushes it into the slab,
Down.

Nothing left, only—
Numbers of gold flooding away,
Memory fragments, stagnant tenants
Inhabiting the few who reminisce,
Who remembers
What's a life worth?
  `,
    date: "2023-06-18",
    type:"poem",
  },
  {
    id: 4,
    title: "To my tired junior year university student",
    content: 
  `
Let me shrug the leech off your hunched sides,
Patch the marks they etched 
In like jagged dagger wounds.
Your gaze invites me to explore, but that firm mouth
Betrays a harsher truth. There’s no place for me there,
But to soothe your distressed heart with a weary ear
And to drag you, through the sand, into a cold plunge.

  Mother has turned to the sayings of a blind old fortune-teller,
  Hoping to turn your luck around.
  Superstitions are an oyster, holding a pearl of truth 
  But mostly the promise and hassle of pungent meat.
  I wouldn’t count on her distraction, personally.

I wish for the twinkling to return in your
Limpid eyes, for your face to be fitted with
A carefree smile. Dismiss that clawing need
For speed, for racing ahead of 
friends, equally clawing for themselves in the rubble  
  `,
    date: "2023-07-11",
    type:"poem",
  },
  {
    id: 5,
    title: "Lost",
    content: 
  `
There are days when the future no longer seems so clear.
When my dream seeps of haziness, lobbed into the stratosphere.
Dangling in the misty melancholy of those days, I watch,
Through the chasm in hope, that hint of you remaining.

Marker trail of your confident steps has
Steeped deep before me,
An inheritance to discover.
Was it kindness that you tossed, or pity?
Yet, you mustn't have expected that
your bread crumb of reeking pride
Has rotted, faltering under the shine of
The moist density of clustered dreams, something
I’ve learnt, finally to embrace.
Now, your dark guides are weary, no longer
Coagulated and imposing. A pity, then, 
That I’ve awoken too late from your
Potion of daze. 
No time for adjustment, 
No time for change. 
My crooked teetering must continue,
stumble blindly between the crushing walls of success.

And when I’ve reached your glowing star,
Standing tall, piled high on my own
tower of rocks,
Finally able to tug at your warm shoulder.
Will you embrace me with that tender touch, 
Or scoff, sneer at my stubborn path?
  `,
  date:"2023-09-24",
  type:"poem",
  },
  {
    id:6,
    title: "Grateful resistance",
    content: 
    `
If I had a dream
to follow,
would I then feel
any less hollow
or simply continue,
this plateau of an existence
lacking soul and 'persistence'.

And if they provided greater assistance
instead of watching me plummet,
fall further from brilliance,
should I then stop my floundering,
start pondering and wandering through this
wide world, seeking blind comfort from the kind?

But if all has been given,
dumped and piled, never hidden.
I fear the wrath that will bubble
when finally, I wake,
no longer dormant in a muddle,
and turn around to find--
  dreams still freshly buried,
  a parent's tender patience,
  the empathy of a friend,
crumpled beneath my trample
and this heart, once ample,
now just loathsome muscle
beating to some dissonant tune.

So I'll shrivel and cower,
plug my ears and shout louder
instead of clawing away,
finding any truth that may glint
in the light like fish scales,
a star in the night.
  `,
  date:"2023-10-02",
  type:"poem", 
  },
  {
    id: 7,
    title: "Jailed: A state of mind",
    content: 
`
My sleep, incomplete, misses the blessed kiss of dream.
For when dream I do, all is corrupted by the festering ache of longing 
Suppressed to the ground whenever your presence draws near.
Yet, a shame summons my will away, when chances appear.
All I am able and capable of is praying
that my dreams are haunted with you, for
 
If you looked at me, I would vacuum the breath from my lungs,
Flatten their lining of translucent film, scrape it like paint against my chest
Just so my heart could swell and at last bear the torrent of
Warmth that bubbles when you approach near.
 
If you looked at me, I would plunge my nails into my wrist, 
claw at the depths and pluck out a tendon to 
craft a drum stick for that fervent heart pummelling at its prison of bones
So maybe then you’d finally hear the roaring beat of my love,
Singing its misfortunes of you.
`,
    date:"2023-10-12",
    type:"poem", 
    },
  {
id: 8,
title: "Time",
content: 
`
Once ran approaching, bright-eyed and curious
Like a freshly born foal.
Though it grew gradually solemn, until it’s strides
surpassed mine, the amniotic sac had yet to tear,
Yet to collapse and sigh.

Then their whispers punctured through, finally,
Ripped through the membrane of safety,
Injecting the virus of doubt until
The foal, that once bounded towards the golden paddock,
had learnt the fear of hurt, of crashing through the wall.
No longer did it 

Soon, I will have to fight against
The strengthening tides, tear through the raging waters
Just to clutch at the memories resting like shells 
On the sea bed.

Until I have to race towards it, towards the fading current of my mortality,
But my footsteps remain imprinted in wet sand,
Chasing after the blurring tear
of the waves.
`,
date:"2023-10-20",
type:"poem", 
},
{
  id:9 ,
  title: "Apology for a brother",
  content: 
  `

  `,
  date:"2023-11-26",
  type:"prose", 
  },
{
id: 10,
title: "The lonely man",
content: 
`
That brother of yours is accompanied
by glints of annoyance and flickering frowns,
whenever brought up.
Your wife
         whispers that he is a putrid parasite,
Your son
         sneers that he is a latent leech,
You
         tell me he is a poor man
                     seduced by his eternal carousel of debt,
                                  doomed by the vortex of failure.

Invested in ridiculous cash grabs,
     	Your wife spits
Addicted to rigged games,
                     Your son sighs,
Lost in unfulfilled promises,
                                  You suggest.

Still, often you dart glances at his crumpled
picture. I watch you shake
your head, decades
of disappointment and fatigue
spilling from the curves in your neck.

You remember him, always,
as that sunshine boy who eagerly rode home
on your father’s bicycle cart. Who jumped
off and tucked the first coin he earned
into your young fingers.
You remember him, not
as the spectral existence of a man who
thinks to trade one fish scale for one million more,
tug his own brother towards the festering quicksand.
It’s evident, from

The way your eyes betray
that glaze of a fish
                      stuck in its seven seconds of memory,
                                  hoping to invoke insight from the past.
How that light smile that bubbles
up on your face
                                               softens the wrinkling of your skin,
                                                           dismisses all memory of the present.
`,
date:"2023-10-23",
type:"poem", 
},
];

/*
{
id: ,
title: "",
content: 
`
`,
date:"",
type:"", 
},
*/

export default poems;