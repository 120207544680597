import React, { Component } from 'react'
import AboutMenuItem from "./AboutMenuItem.js"
import AboutSubheading from "./AboutSubheading.js"
import subheadingsData from "./subheadingsData.js"
import personalIcon from "../assets/WhoAmI.png"
import educationIcon from "../assets/KnowMe.png"
import careerIcon from "../assets/Website.png"


export default class AboutMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeMenuItem: 1,
      activeSubheading: 1,
    }
  }

  handleMenuItemClick = (menuItem) => {
    this.setState({
      activeMenuItem: menuItem,
      activeSubheading: 1,
    })
  }

  handleSubheadingClick = (subheading) => {
    this.setState({
      activeSubheading: subheading,
    })
  }

  render() {

    const { activeMenuItem, activeSubheading } = this.state;
    const menuItems = ["WHO AM I?", "GET TO KNOW ME", "WEBSITE INFO"]
    const activeMenuTitle = menuItems[activeMenuItem - 1];
    const activeMenuIcon =
      activeMenuTitle === "WHO AM I?" ? personalIcon :
        activeMenuTitle === "GET TO KNOW ME" ? educationIcon
          : careerIcon;

    const subheadings = subheadingsData[activeMenuItem];

    return(
    <>
      <div className='menu'>
        {menuItems.map((item, index) => (
          <AboutMenuItem
            key={index}
            title={item}
            active={activeMenuItem === index + 1}
            onClick={() => this.handleMenuItemClick(index + 1)}
          />
        ))}
      </div>
      <div className='sub-container'>
        <div className='icon-title-container'>
          <img src={activeMenuIcon} alt={activeMenuTitle}
          className="icon"/>
          <h1 className='subtitle'>{activeMenuTitle}</h1>
        </div>
        {subheadings.map((subheading, index) => (
          <AboutSubheading
            key={index}
            title={subheading.title}
            content={subheading.content}
            active={activeSubheading === index + 1}
            onClick={() => this.handleSubheadingClick(index + 1)}
            menuItem= {activeMenuItem}
          />
        ))}
      </div>
    </>
    )
  }
}
