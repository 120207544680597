import React from 'react'
import {Link, useLocation} from "react-router-dom";
import "../styles/nav.css"
import about from "../assets/AboutMe.png"
import photos from "../assets/Photos.png"
import art from "../assets/Art.png"
import projects from "../assets/Projects.png"
import blog from "../assets/Blog.png"

export default function Nav() {
  const location = useLocation();

  const getNavPositionClass = () => {
    switch (location.pathname) {
      case "/":
        return "nav-about";
        case "/life":
          return "nav-life"
      case "/art":
        return "nav-art";
      case "/projects":
        return "nav-projects";
      case "/blog":
        return "nav-blog";
      default:
        return "";
    }
  };

  const getPageTitle = () => {
    switch (location.pathname) {
      case "/":
        return "ABOUT";
        case "/life":
          return "LIFE"
      case "/art":
        return "ART";
      case "/projects":
        return "PROJECTS";
      case "/blog":
        return "BLOG";
      default:
        return "";
    }
  };

  const navPositionClass = getNavPositionClass();
  const pageTitle = getPageTitle();

  const isCurrentPage = (navClass) => {
    return navClass === navPositionClass;
  };

  const renderNavLink = (to, imgSrc, altText, navClass) => {
    const isCurrent = isCurrentPage(navClass);
    const linkClass = isCurrent ? "nav-link current" : "nav-link";

    return (
      <Link to={to} className={linkClass}>
        <img src={imgSrc} alt={altText} />
        {isCurrent && <h1 className="page-title">{pageTitle}
        </h1>}
      </Link>
    );
  };

  return (
    <nav className = {`nav ${navPositionClass}`}>
  {renderNavLink("/", about,"About Me Icon","nav-about")}
  {renderNavLink("/life",photos,"Photograph Icon","nav-life")}
  {renderNavLink("/art", art,"Art Icon","nav-art")}
  {renderNavLink("/projects", projects,"Projects Icon","nav-projects")}
  {renderNavLink("/blog", blog, "Blog Icon", "nav-blog")}
  </nav>
  );
};